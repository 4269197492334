.App {
    text-align: left;
}

h1 a:link, h1 a:visited, h1 a:active {
    color: black;
}

nav {
    overflow-x: auto;
    white-space: nowrap;
    height: 50px;
    margin-bottom: 1.5rem;
}

nav ul {
    display: flex;
    text-align: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
}

nav ul li {
    display: block;
    margin: 0;
    padding: 0;
}

nav ul li a:link, nav ul li a:visited {
    border-bottom-color: #4a4a4a;
    color: #4a4a4a;
}

nav ul li a {
    padding: 0.5rem 1rem;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #4a4a4a;
    font-size: 1.25rem;
}

nav ul li a.active {
    border-bottom-color: #3273dc;
    color: #3273dc;
}

nav ul li a:hover {
    border-bottom-color: #000000;
    color: #000000;
}

p {
    max-width: 800px;
    padding-bottom: 10px;
}

a:link, a:visited {
    color: #485fc7;
}

a:hover {
    color: #000000;
}

code {
    color: red;
}

